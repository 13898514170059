import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const KalaHariFullwidth = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-3.jpg" alt="Kalahari Brand Identity Presentation 3 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-4.jpg" alt="Kalahari Brand Identity Presentation 4 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-5.jpg" alt="Kalahari Brand Identity Presentation 5 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-1.jpg" alt="Kalahari Brand Identity Presentation 1 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-2.jpg" alt="Kalahari Brand Identity Presentation 2 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-6.jpg" alt="Kalahari Brand Identity Presentation 6 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-7.jpg" alt="Kalahari Brand Identity Presentation 7 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-8.jpg" alt="Kalahari Brand Identity Presentation 8 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-9.jpg" alt="Kalahari Brand Identity Presentation 9 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-10.jpg" alt="Kalahari Brand Identity Presentation 10 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-11.jpg" alt="Kalahari Brand Identity Presentation 11 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-12.jpg" alt="Kalahari Brand Identity Presentation 12 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-13.jpg" alt="Kalahari Brand Identity Presentation 13 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-14.jpg" alt="Kalahari Brand Identity Presentation 14 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-15.jpg" alt="Kalahari Brand Identity Presentation 15 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-16.jpg" alt="Kalahari Brand Identity Presentation 16 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-17.jpg" alt="Kalahari Brand Identity Presentation 17 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/kalahari/Kalahari-presentation-18.jpg" alt="Kalahari Brand Identity Presentation 18 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                                                                                                                
            </Row>
        </Container>
    </section>
  )
}

export default KalaHariFullwidth