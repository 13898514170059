import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../../icon/buttonIcon"

const KalahariMoreBranding = () => {
  return (
    <section className="home-cs-listing branding-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              More Branding Projects
            </h2>
          </Col>
        </Row>
        <Row className="wrapper-main">          
            <Col lg={4} className="mt-md-0 mt-3">
                <Link to="/branding/nubinix/" className="home-cs-link first">
                    <div className="hcs-img">
                    <StaticImage
                        src="../../images/branding/nubinix/nubinix-listing-image.png"
                        alt="Nubinx Branding Project | WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                    />
                    </div>
                    <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>IT/Consulting</span>
                    <div className="cs-header">
                        <h3>
                            Nubinix
                        </h3>
                    </div>
                    <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read More</span>
                    </div>
                    </div>
                </Link>
            </Col>                    
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/trinity-dex/" className="home-cs-link middle">
                <div className="hcs-img">
                    <StaticImage
                    src="../../images/branding/trinity-dex/TrinityDexlistingimage.png"
                    alt="Trinity Dex Projects Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                    />
                </div>
                <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>IT/Consulting</span>
                    <div className="cs-header">
                    <h3>
                        TrinityDex
                    </h3>
                    </div>
                    <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/square-projects/" className="home-cs-link last">
                    <div className="hcs-img">
                    <StaticImage
                        src="../../images/branding/bloom-botanics/bloom-botanics-listing-img.png"
                        alt="Bloom Botanics Branding Project | WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                    />
                    </div>
                    <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Construction</span>
                    <div className="cs-header">
                        <h3>
                        Square Projects
                        </h3>
                    </div>
                    <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read More</span>
                    </div>
                    </div>
                </Link>
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default KalahariMoreBranding
