import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import KalahariMain from "../../components/branding/kalahari/kalahariMain"
import KalaHariFullwidth from "../../components/branding/kalahari/kalahariFullwidth"
import KalahariMoreBranding from "../../components/branding/kalahari/kalhariMoreBranding"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/kalahari.scss"

import brandingFeaturedImage from "../../../static/branding/kalahari/kalahari-featured-image.png"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Kalahari Bank Branding Case Study | WowMakers"
            description="Explore how we transformed Kalahari Bank's identity with our innovative branding approach. See the power of strategic design."
        />
    </>
)

const KalahariBankBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-kalahari"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <KalahariMain />
            <KalaHariFullwidth />
            <KalahariMoreBranding />
        </Layout>
    )
}

export default KalahariBankBranding
