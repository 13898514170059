import React from "react"
import { Container, Row } from "react-bootstrap"

import "../../sass/global/scroll-indication.scss"


const ScrollIndicationHeightZero = () => {
  return (
    <div className="scroll-bar scrollbartop height-zero">
        <Container fluid>
            <Row>
                <div id="scroll_container">
                    <div id="horizontal_scroll"></div>            
                </div>
            </Row>
        </Container>
    </div>
  )
}

export default ScrollIndicationHeightZero
